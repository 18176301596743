<template>
  <el-dialog
    :visible.sync="visible"
    :title="mode ? '新增试卷库分类' : '修改试卷库分类'"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="formData" :rules="ruleValidate" label-width="132px" class="yt-form yt-dialog-form" :validate-on-rule-change="false">
      <el-form-item label="名称" prop="paperName">
        <el-input maxlength="20" show-word-limit v-model="formData.paperName" />
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-button @click="visible = false" class="button">取消</el-button>
      <el-button type="primary" class="button" :loading="loading" @click="submit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import paperCategoryApi from '@/api/paperBankCategory'
export default {
  name: 'PaperBankCategoryCUDialog',
  data() {
    const validateName = (rule, value, callback) => {
      let reg = /^(?=.*\S).+$/
      if (!reg.test(value)) {
        callback(new Error('试卷分类名称不能全为空格'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      mode: true,
      formData: {
        paperName: '',
        parentId: 0, //父节点id,若无父节点,则为0
        categoryId: ''
      },
      ruleValidate: {
        paperName: [
          { required: true, message: '试卷库分类名称不能为空' },
          { max: 20, message: '试卷库分类名称长度不能超过20个字符' },
          { min: 1, message: '试卷库分类名称长度不小于1个字符' },
          { validator: validateName, trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  methods: {
    initData(row, isParent) {
      if (isParent) {
        this.formData.parentId = row.value
      } else {
        this.formData = {
          paperName: row.label,
          categoryId: row.value,
          parentId: row.parentId,
          children: row.children
        }
      }
    },
    open(mode) {
      this.mode = mode
      this.visible = true
    },
    submit() {
      if (this.mode) {
        this.create()
      } else {
        this.update()
      }
    },
    update() {
      this.$refs.form.validate(val => {
        if (val) {
          let payload = {
            name: this.formData.paperName,
            categoryId: this.formData.categoryId
          }
          this.loading = true
          paperCategoryApi
            .categoryModify(payload)
            .then(res => {
              if (res.code === 0) {
                this.$message.success('修改成功')
                this.visible = false
                let node = {
                  value: this.formData.categoryId,
                  label: this.formData.paperName,
                  parentId: this.formData.parentId,
                  children: this.formData.children
                }
                this.$emit('refresh', node)
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    create() {
      this.$refs.form.validate(val => {
        if (val) {
          let payload = {
            name: this.formData.paperName,
            parentId: this.formData.parentId
          }
          this.loading = true
          paperCategoryApi
            .addBankCategory(payload)
            .then(res => {
              if (res.code === 0) {
                this.$message.success('添加成功')
                this.visible = false
                let node = {
                  value: res.res,
                  label: this.formData.paperName,
                  parentId: this.formData.parentId
                }
                this.$emit('refresh', node)
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.$refs.form.resetFields()
      this.formData.parentId = 0
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped></style>
