<template>
  <el-dialog
    :visible.sync="visible"
    title="移动到分类"
    :close-on-click-modal="false"
    class="yt-dialog yt-dialog-default"
    :before-close="close"
    @closed="closed"
  >
    <div class="moveClass">
      <el-tree @node-click="lessonMove" :data="paperBankCategoryData" ref="tree"></el-tree>
    </div>
    <div class="footer" slot="footer">
      <el-button @click="visible = false" class="button">取消</el-button>
      <el-button type="primary" :loading="loading" @click="moveBankCategory" class="button">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import paperApi from '@/api/paper'
export default {
  name: 'PaperBankMoveDialog',
  data() {
    return {
      visible: false,
      paperBankCategoryData: [],
      movePaperId: '',
      selectBank: [],
      categoryId: 0,
      loading: false
    }
  },
  methods: {
    open(data, value) {
      this.paperBankCategoryData = data
      this.visible = true
      this.selectBank = value
    },
    moveBankCategory() {
      this.loading = true
      let payload = {
        categoryId: this.movePaperId,
        questionBankIds: this.selectBank
      }
      paperApi
        .moveBankCategory(payload)
        .then(res => {
          if (res.code === 0) {
            this.$message.success('试卷库移动成功')
            this.visible = false
            this.$emit('on-success')
          } else {
            this.loading = false
          }
        })
        .catch(err => {
          this.loading = false
        })
    },
    lessonMove(data) {
      this.movePaperId = data.value
    },
    closed() {
      this.selectBank = []
      this.loading = false
    },
    close(done) {
      done()
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/tableDropdownMenu';
</style>
