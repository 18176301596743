import config from '../config/config'
import { axios } from './index'

const paperBankCategoryUrl = config.baseUrl + '/paper/api/v1/paper/bank/category'
export default {
  //获取试卷库分类(平铺数据）
  getAllPaperBank() {
    return axios.get(`${paperBankCategoryUrl}/get/all`)
  },
  // 获取企业试卷库分类树
  getCategoryListTree(categoryId) {
    return axios.get(`${paperBankCategoryUrl}/tree?categoryId=${categoryId}`)
  },
  // 删除试卷库分类
  deleteCategoryBank(categoryId) {
    return axios.post(`${paperBankCategoryUrl}/delete?categoryId=${categoryId}`)
  },
  // 修改试卷库分类
  categoryModify(data) {
    return axios.post(`${paperBankCategoryUrl}/modify`, data)
  },
  // 添加试卷库分类
  addBankCategory(data) {
    return axios.post(`${paperBankCategoryUrl}/add`, data)
  }
}
