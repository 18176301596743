<template>
  <el-dialog
    :visible.sync="visible"
    :title="mode ? '新建试卷库' : '修改试卷库'"
    width="484"
    destroy-on-close
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="paperBankForm" :rules="ruleValidate" label-width="132px" class="yt-form yt-dialog-form">
      <el-form-item label="名称" prop="name">
        <el-input v-model="paperBankForm.name" placeholder="请输入试卷库名称" />
      </el-form-item>
      <el-form-item label="封面" prop="cover">
        <el-upload
          class="cover"
          action=""
          ref="coverUpload"
          accept=".png,.jpg"
          :show-file-list="false"
          :http-request="handleImgSize"
          :on-change="handleChange"
        >
          <template v-if="paperBankForm.photoUrl">
            <el-image class="cover" :src="paperBankForm.photoUrl"></el-image>
            <div class="upload-update">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-shangchuan" />
              </svg>
              <p>上传</p>
            </div>
          </template>
          <template v-else>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shangchuan" />
            </svg>
            <p>上传</p>
          </template>
        </el-upload>
        <div class="upload-tip">
          <i class="el-icon-warning-outline" style="margin-right: 5px"></i>
          仅支持JPG、PNG格式，1mb以内的图片
        </div>
        <canvas id="uploadCanvas" width="32" height="32" style="display: none"></canvas>
      </el-form-item>
      <el-form-item label="描述" prop="description">
        <el-input type="textarea" v-model="paperBankForm.description" :rows="12" placeholder="请输入试卷库描述" />
        <div class="form-tip" v-if="!ruleValidate.description.error">
          不超过200个字
        </div>
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-button @click="visible = false" class="button">取消</el-button>
      <el-button type="primary" class="button" @click="submit" :loading="loading">确定 </el-button>
    </div>
  </el-dialog>
</template>
<script>
import paperApi from '@api/paper'
import ossApi from '@api/oss'

export default {
  data() {
    const that = this
    return {
      percentage: 0,
      uploadUrl: '',
      serveLoca: '',
      paperBank: {},
      uploadSuccess: false,
      isUploading: false,
      visible: false,
      mode: true,
      categoryId: null,
      uploadFile: {},
      imageUrl: '',
      uploadParams: {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',
        Signature: ''
      },
      loading: false,
      paperBankForm: {
        name: '',
        description: '',
        photoUrl: ''
      },
      ruleValidate: {
        name: {
          required: true,
          error: false,
          validator: (rule, value, callback) => {
            if (value.trim() === '') {
              that.ruleValidate.name.error = true
              callback(new Error('试卷库名称不为空'))
            } else if (value.length > 30) {
              that.ruleValidate.name.error = true
              callback(new Error('试卷库名称不超过30个字'))
            } else {
              that.ruleValidate.name.error = false
              callback()
            }
          }
        },
        description: {
          type: 'string',
          error: false,
          validator: (rule, value, callback) => {
            if (value.length > 200) {
              that.ruleValidate.description.error = true
              callback(new Error('试卷库描述不超过 200 字'))
            } else {
              that.ruleValidate.description.error = false
              callback()
            }
          }
        }
      }
    }
  },
  methods: {
    open(data) {
      this.visible = true
      this.categoryId = data
    },
    updatePaperBank(row) {
      this.visible = true
      this.mode = false
      this.paperBank = row
      this.paperBankForm.name = row.name
      this.paperBankForm.description = row.description
      this.paperBankForm.photoUrl = row.photoUrl
    },
    handleChange(file) {
      this.uploadFile = file
    },
    handleImgSize() {
      if (this.uploadFile.size > 1024000) {
        this.$message.warning('请上传小于1mb的图片')
        return
      }
      let fileName = this.uploadFile.name.slice(0, -4)
      fileName = fileName.replace(/[ |~`!@#$%^&*()\-_+=\\\[\]{};:"',<.>\/?，。；：“”》《、]/g, '')
      this.uploadFile.name = fileName + (this.uploadFile.raw.type === 'image/png' ? '.png' : '.jpg')
      let c = document.getElementById('uploadCanvas')
      let ctx = c.getContext('2d')
      let reader = new FileReader()
      reader.onloadend = e => {
        let img = new Image()
        img.src = e.target.result
        img.onload = () => {
          ctx.drawImage(img, 0, 0, 32, 32)
          let dataURL = this.uploadFile.raw.type === 'image/png' ? c.toDataURL('image/png') : c.toDataURL('image/jpeg')
          let file = this.$dataUrlToFile(dataURL, this.uploadFile.name)
          if (window.uploadUrl) {
            let formData = new FormData()
            formData.append('file', file, this.uploadFile.name)
            ossApi.upCover(formData).then(res => {
              this.imageUrl = res.res
              this.paperBankForm.photoUrl = res.res
            })
          } else {
            ossApi.getQuestionBankCoverToken(new Date().valueOf(), this.uploadFile.name).then(res => {
              let data = res.res
              this.uploadParams.key = data.dir
              this.uploadParams.OSSAccessKeyId = data.accessId
              this.uploadParams.policy = data.policy
              this.uploadParams.Signature = data.signature
              let formData = new FormData()
              formData.append('key', this.uploadParams.key)
              formData.append('OSSAccessKeyId', this.uploadParams.OSSAccessKeyId)
              formData.append('policy', this.uploadParams.policy)
              formData.append('Signature', this.uploadParams.Signature)
              formData.append('file', file)
              fetch(`https://${data.host}`, {
                method: 'POST',
                body: formData
              }).then(result => {
                this.imageUrl = `https://${data.host}/${this.uploadParams.key}`
                this.paperBankForm.photoUrl = `https://${data.host}/${this.uploadParams.key}`
              })
            })
          }
        }
      }
      reader.readAsDataURL(this.uploadFile.raw)
    },
    submit() {
      if (this.mode) {
        this.create()
      } else {
        this.update()
      }
    },
    closed() {
      this.$refs.form.resetFields()
      this.visible = false
      this.mode = true
      this.isUploading = false
      this.loading = false
      this.uploadSuccess = false
    },
    close(done) {
      done()
    },
    create() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          let paperBankInfo = Object.assign(this.paperBankForm)
          paperBankInfo.categoryId = this.categoryId
          paperApi
            .addPaperBank(paperBankInfo)
            .then(res => {
              if (res.code === 0) {
                this.$message.success('新建成功')
                this.$emit('on-success')
                this.$refs.form.resetFields()
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    update() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          let paperBankInfo = Object.assign(this.paperBankForm)
          paperBankInfo.id = this.paperBank.paperBankId
          paperBankInfo.categoryId = this.categoryId
          paperApi
            .modifyPaperBank(paperBankInfo)
            .then(res => {
              if (res.res === 1) {
                this.$message.success('修改成功')
                this.$emit('on-success')
                this.$refs.form.resetFields()
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.cover {
  width: 124px;
  height: 64px;
  ::v-deep .el-upload {
    p {
      height: 13px;
      line-height: 13px;
    }
  }
}
.upload-update {
  p {
    height: 13px;
    line-height: 13px;
  }
}
.upload-tip {
  margin-left: 128px;
}
::v-deep .yt-dialog-form {
  text-align: left;
}
</style>
