import config from '../config/config'
import { axios } from './index'

const paperBankUrl = config.baseUrl + '/paper/api/v1/bank'

export default {
  //根据分类id查询试卷库
  getPaperBankByCategory(data) {
    return axios.post(`${paperBankUrl}/search/bycategory?categoryId=${data.categoryId}`)
  },
  //分页获取企业试卷列表
  getEnterprisePaper(data) {
    return axios.post(`${paperBankUrl}/search/inner`, data)
  },
  //分页获取平台试卷列表
  getPlatformPaper(data) {
    return axios.post(`${paperBankUrl}/search/outer`, data)
  },
  //批量删除企业试卷库
  deletePaperBanks(paperBankIds) {
    return axios.post(`${paperBankUrl}/delete/paper-banks`, paperBankIds)
  },
  //获取试卷库分类
  getAllPaperBank() {
    return axios.get(`${paperBankUrl}/paper/bank/category/get/all`)
  },
  //获取试卷库
  getPaperBankList(id) {
    return axios.get(`${paperBankUrl}/get/all/besides?paperBankId=${id}`)
  }
}
