<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>试卷管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-header">
      <el-menu class="yt-menu" :default-active="activeIndex" mode="horizontal" @select="selectMenu">
        <el-menu-item index="1">企业</el-menu-item>
        <el-menu-item index="0">平台</el-menu-item>
      </el-menu>
      <div class="yt-header-toolbar">
        <el-input v-model="keyword" placeholder="通过名称搜索试卷库" class="yt-search" @keydown.enter.native="judge">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="judge" />
        </el-input>
        <el-select v-model="postSelection" placeholder="搜索岗位模型" class="yt-search-select" clearable multiple collapse-tags @change="judge">
          <el-option v-for="item in postData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
        <div style="display: flex;" v-if="activeIndex === '1'">
          <el-button type="primary" size="small" @click="addPaperBank" style="width: 80px">
            <div>
              <i class="el-icon-circle-plus-outline" />
              <p>新建</p>
            </div>
          </el-button>
          <el-button size="small" @click="movePaper(false)" style="width: 80px">
            <div>
              <YTIcon :href="'#icon-yidong'" />
              <p>移动</p>
            </div>
          </el-button>
          <el-button size="small" @click="deletePaper(false)" style="width: 80px">
            <div>
              <YTIcon :href="'#icon-shanchu'" />
              <p>删除</p>
            </div>
          </el-button>
          <el-button size="small" @click="seePaper" style="width:136px">
            <div>
              <YTIcon :href="'#icon-chakan'" />
              <p>查看所有考试</p>
            </div>
          </el-button>
        </div>
      </div>
    </div>
    <div
      class="yt-container"
      :class="{
        'yt-container-flex': activeIndex === '1',
        'is-pack-up': isPackUp
      }"
      style="height: calc(100% - 48px)"
    >
      <div class="yt-content-tree has-footer" v-if="activeIndex === '1'">
        <template v-if="isPackUp">
          <YTIcon class="pack-up-button" :href="'#icon-zhankai'" @click="isPackUp = false" />
        </template>
        <template v-else>
          <div class="header">
            <YTIcon class="pack-up-button" :href="'#icon-shouqi1'" @click="isPackUp = true" />
            <YTIcon style="margin: 0 5px; font-size: 20px" :href="'#icon-fenlei1'" />
            <p>{{ activeIndex === '0' ? '平台' : '企业' }}试卷库分类</p>
          </div>
          <div class="tree has-tool">
            <el-tree ref="category-tree" :data="paperBankCategoryData" node-key="value" :highlight-current="true" @node-click="handleNodeClick">
              <div slot-scope="{ node, data }" class="tree-node">
                <el-tooltip effect="dark" :content="node.label" :disabled="node.label.length < 10" placement="top-start">
                  <div class="el-tree-node__label">{{ node.label }}</div>
                </el-tooltip>
                <div v-if="data.value" class="tools">
                  <span class="tool-button" @click.stop="toCreatePaper(data)">添加</span>
                  <span class="tool-button" @click.stop="toUpdatePaper(data)">修改</span>
                  <span class="tool-button" @click.stop="toDeletePaper(data)">删除</span>
                </div>
              </div>
            </el-tree>
          </div>
          <div class="footer">
            <el-button style="width: 138px" size="small" @click="toCreatePaper(false)">
              <div>
                <i class="el-icon-circle-plus-outline" />
                <p>新建试卷库分类</p>
              </div>
            </el-button>
          </div>
        </template>
      </div>
      <div class="yt-resize" v-if="activeIndex === '1'">
        ⋮
      </div>
      <div class="yt-content">
        <el-table
          ref="paperBankList"
          class="yt-table"
          height="calc(100% - 35px)"
          :data="paperBankList"
          v-loading="paperBankLoading"
          element-loading-text="加载中"
          @selection-change="selectChange"
        >
          <el-table-column type="selection" width="31" />
          <el-table-column label="试卷库名称" prop="name" show-overflow-tooltip />
          <el-table-column label="试卷数量" prop="paperAmount" show-overflow-tooltip />
          <el-table-column label="描述" prop="description" show-overflow-tooltip />
          <el-table-column label="创建者" prop="creatorName" show-overflow-tooltip v-if="activeIndex === '1'" />
          <el-table-column label="创建时间" show-overflow-tooltip>
            <template slot-scope="scope">{{ $formatTime(scope.row.createTime, 'yyyy-MM-dd hh:mm') }}</template>
          </el-table-column>
          <el-table-column label="操作" width="120px">
            <template slot-scope="scope">
              <span class="tool-button"><span @click="handleOnclick(scope.row)">查看</span></span>
              <span class="tool-button" v-if="activeIndex === '1'"><span @click="movePaper(true, scope.row)">移动</span></span>
              <el-dropdown placement="bottom-start" v-if="activeIndex === '1'" @command="handleCommand($event, scope.row)">
                <span class="tool-button">更多</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="update">修改</el-dropdown-item>
                  <el-dropdown-item command="delete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <div slot="empty" id="empty-slot">
            <template v-if="!paperBankLoading">
              <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px" />
            </template>
          </div>
        </el-table>
        <Page
          class="yt-page"
          :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
          :total="total"
          :current="paperPageForm.pageNumber + 1"
          :page-size-opts="[10, 20, 40, 100]"
          :page-size="paperPageForm.pageSize"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
          show-total
          show-elevator
          show-sizer
        />
      </div>
    </div>
    <ConfirmDialog ref="paperDeleteDialog" :title="'删除试卷库分类'" @on-ok="delNewBank">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        确认删除试卷库分类吗？
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="deletePaperBankDialog" :title="'删除试卷库'" @on-ok="deletePaperBank">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        确认删除试卷库吗？
      </div>
    </ConfirmDialog>
    <PaperBankMoveDialog ref="paperBankMoveDialog" @on-success="judge"></PaperBankMoveDialog>
    <PaperBankCategoryCUDialog ref="paperBankCategoryCUDialog" @refresh="toCloseCategoryCu"></PaperBankCategoryCUDialog>
    <PaperBankCUDialog ref="paperBankCUDialog" @on-success="judge" />
  </div>
</template>

<script>
import YTIcon from '@/components/common/YTIcon'
import paperBank from '@/api/paperBank'
import paperApi from '@/api/paper'
import paperCategoryApi from '@/api/paperBankCategory'
import ConfirmDialog from '@/components/common/dialog/ConfirmDialog'
import PaperBankCategoryCUDialog from '@/components/common/dialog/PaperBankCategoryCUDialog'
import PaperBankCUDialog from '@/components/common/dialog/PaperBankCUDialog'
import PaperBankMoveDialog from '@/components/common/dialog/PaperBankMoveDialog'
import postApi from '@/api/post'
export default {
  name: 'PaperBank',
  components: {
    YTIcon,
    ConfirmDialog,
    PaperBankCategoryCUDialog,
    PaperBankCUDialog,
    PaperBankMoveDialog
  },
  data() {
    return {
      activeIndex: '1',
      isPackUp: false,
      keyword: '',
      movePaperId: '',
      deleteLoading: false,
      paperBank: {
        type: Object,
        required: true
      },
      addPaperBankModal: false,
      modifyPaperBankModal: false,
      parentData: {},
      deleteData: {}, //存储要删除节点的数据
      selectBank: [],
      delTestId: '',
      moveClass: false,
      categoryId: 0,
      paperBankList: [],
      deletePaperBankModal: false,
      paperBankLoading: true,
      paperPageForm: {
        pageNumber: 0,
        pageSize: 10
      },
      total: 0,
      paperBankCategoryData: [],
      postData: [], //岗位模型数据
      postSelection: []
    }
  },
  created() {
    this.reSearchPaperBank()
    this.getCategoryListTree(0)
    this.getPostData()
  },
  mounted() {
    this.$dragDiv()
  },
  methods: {
    getPostData() {
      //获取岗位模型
      postApi.getPostTypeOrg(this.activeIndex === '1').then(res => {
        this.postData = res.res
      })
    },
    addPaperBank() {
      this.$refs.paperBankCUDialog.open(this.categoryId)
    },
    toCloseCategoryCu(data) {
      if (this.$refs.paperBankCategoryCUDialog.mode) {
        this.$refs['category-tree'].append(data, this.parentData.hasOwnProperty('parentId') ? this.parentData : null)
      } else {
        this.updateTreeNode(this.paperBankCategoryData, data)
      }
      this.parentData = {}
    },
    updateTreeNode(treeNode, data) {
      if (treeNode) {
        treeNode.forEach(res => {
          if (res.value === data.value) {
            this.$set(res, 'label', data.label)
            this.$forceUpdate()
          }
          if (res.hasOwnProperty('children')) {
            this.updateTreeNode(res.children, data)
          }
        })
      }
    },
    judge() {
      if (this.activeIndex === '1') {
        this.reSearchPaperBank()
      } else {
        this.reSearchPlatformPaper()
      }
    },
    closeTree() {
      this.selectBank = []
      this.$refs.paperBankList.clearSelection()
      this.judge()
    },
    selectMenu(index) {
      this.activeIndex = index
      this.paperBankList = []
      this.keyword = ''
      this.paperPageForm.pageNumber = 0
      this.paperPageForm.pageSize = 10
      this.postSelection = []
      this.judge()
      this.getPostData()
      this.$nextTick(() => {
        if (this.activeIndex === '1') {
          this.$dragDiv()
        }
      })
    },
    selectChange(val) {
      this.selectBank = val.map(item => {
        return item.paperBankId
      })
    },
    handleCommand(command, row) {
      if (command === 'update') {
        this.$refs.paperBankCUDialog.updatePaperBank(row)
      } else if (command === 'delete') {
        this.deletePaper(true, row)
      }
    },
    handleOnclick(row) {
      this.$router.push({
        name: 'paperList',
        params: {
          paperBankId: row.paperBankId,
          paperBankName: row.name
        }
      })
    },
    seePaper() {
      this.$router.push({
        path: '/manage/exam/list',
        // path: '/exam/paperlist',
        query: {
          categoryId: this.categoryId,
          tabLabel: this.$route.query.tabLabel,
          paperBankId: this.$route.query.paperBankId
        }
      })
    },
    movePaper(isSingle, data) {
      if (isSingle) {
        this.selectBank = [data.paperBankId]
        this.$refs.paperBankMoveDialog.open(this.paperBankCategoryData.slice(1), this.selectBank)
      } else {
        if (this.selectBank.length === 0) {
          this.$message.warning('请选择要移动的试卷库')
          return
        }
      }
      this.$refs.paperBankMoveDialog.open(this.paperBankCategoryData.slice(1), this.selectBank)
    },
    toDeletePaper(data) {
      this.delTestId = data.value
      this.$refs.paperDeleteDialog.open()
    },
    deletePaper(isSingle, data) {
      if (isSingle) {
        this.selectBank = [data.paperBankId]
      } else {
        if (this.selectBank.length === 0) {
          this.$message.warning('请先勾选要删除的试卷')
          return
        }
      }
      this.$refs.deletePaperBankDialog.open()
    },
    reSearchPaperBank() {
      let payload = {
        categoryId: this.categoryId,
        page: this.paperPageForm,
        searchContent: this.keyword,
        postIds: this.postSelection
      }
      this.paperBankLoading = true
      paperBank
        .getEnterprisePaper(payload)
        .then(res => {
          if (res.res.total > 0 && Math.ceil(res.res.total / this.paperPageForm.pageSize) < this.paperPageForm.pageNumber + 1) {
            this.paperPageForm.pageNumber = Math.ceil(res.res.total / this.paperPageForm.pageSize) - 1
            this.reSearchPaperBank()
          } else {
            this.paperBankList = res.res.data
            this.total = res.res.total
          }
        })
        .finally(() => {
          this.paperBankLoading = false
        })
    },
    reSearchPlatformPaper() {
      let payload = {
        page: this.paperPageForm,
        searchContent: this.keyword,
        postIds: this.postSelection
      }
      this.paperBankLoading = true
      paperBank
        .getPlatformPaper(payload)
        .then(res => {
          this.paperBankList = res.res.data
          this.total = res.res.total
        })
        .finally(() => {
          this.paperBankLoading = false
        })
    },
    //旧方法
    getCategoryListTree(data) {
      paperCategoryApi.getCategoryListTree(data).then(res => {
        let arr = JSON.stringify(res.res)
        this.paperBankCategoryData = JSON.parse(
          arr
            .replace(/name/g, 'label')
            .replace(/categoryTreeVOS/g, 'children')
            .replace(/id/g, 'value')
        )
        this.paperBankCategoryData.unshift({
          value: 0,
          status: 0,
          categoryId: 0,
          label: '所有试卷库'
        })
        this.setCurrentKey()
      })
    },
    toCreate() {},
    delNewBank() {
      paperApi.deleteCategoryBank(this.delTestId).then(res => {
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.delTest = false
          this.getCategoryListTree(0)
        }
      })
    },
    //新建分类
    toCreatePaper(data) {
      if (data) {
        this.parentData = data
        this.$nextTick(() => {
          this.$refs.paperBankCategoryCUDialog.initData(data, true)
        })
      }
      this.$refs.paperBankCategoryCUDialog.open(true)
    },
    //修改分类
    toUpdatePaper(data) {
      this.$nextTick(() => {
        this.$refs.paperBankCategoryCUDialog.initData(data, false)
      })
      this.$refs.paperBankCategoryCUDialog.open(false)
    },
    deletePaperBank() {
      this.deleteLoading = true
      paperBank
        .deletePaperBanks(this.selectBank)
        .then(res => {
          if (res.res === 1) this.$message.success('删除试卷库成功')
          this.judge()
        })
        .finally(() => {
          this.deleteLoading = false
        })
    },
    handleNodeClick(data) {
      this.categoryId = data.value
      this.paperPageForm.pageNumber = 0
      this.paperPageForm.pageSize = 10
      this.judge()
    },
    setCurrentKey(value) {
      this.$nextTick(() => {
        this.$refs['category-tree'].setCurrentKey(value ? value : this.categoryId)
      })
    },
    changePage(pageNum) {
      this.paperPageForm.pageNumber = pageNum - 1
      this.judge()
    },
    changePageSize(pageSize) {
      this.paperPageForm.pageSize = pageSize
      this.judge()
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/tableDropdownMenu';
@import '~@/theme/dragDiv';
.yt-header-toolbar {
  .yt-search,
  .yt-search-select {
    width: auto;
    max-width: 234px;
  }
}
</style>
